import React from "react";
import "./index.css";

const Send = () => {
  return (
    <div className="container-lg send">
      <div className="containerPop activePop">
        <div className="flex flexRow">
          <div className="textCol">
            <h1 className="relative">
              <span>Tu solicitud fué recibida</span>
            </h1>
            <h3>
              Nuestros especialistas están buscando las mejores opciones y
              propuestas, en minutos nos contactaremos.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Send;
