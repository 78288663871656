const horarioOptions = [
  { value: "Por la mañana", text: "Por la mañana" },
  { value: "Por la tarde", text: "Por la tarde" },
  { value: "Por la noche", text: "Por la noche" },
  { value: "Cualquier horario", text: "Cualquier horario" }
];

const adicionalOptions = [
  {
    option: "a",
    value: "Si",
    defaultChecked: true
  },
  {
    option: "b",
    value: "No (Solo Alarma)",
    defaultChecked: false
  }
];

const alarmaOptions = [
  {
    option: "a",
    value: "Casa",
    ambientes: ["1 Amb", "2 Amb", "3 Amb", "Más de 3 Amb"]
  },
  {
    option: "b",
    value: "Comercio"
  }
];

const ambientesOptions = [
  {
    option: "a",
    value: "1 Amb"
  },
  {
    option: "b",
    value: "2 Amb"
  },
  {
    option: "C",
    value: "3 Amb"
  },
  {
    option: "D",
    value: "Más de 3 Amb"
  }
];

export { horarioOptions, adicionalOptions, alarmaOptions, ambientesOptions };
