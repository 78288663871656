import React from "react";
import "./index.css";

// Icons
import radar from "../../assets/radar.svg";
import scan from "../../assets/scan.svg";
import security from "../../assets/security.svg";

const Seguridad = () => {
  const tranquilidadList = [
    {
      title: "ASISTENCIA 24HRS",
      description:
        "Nuestro equipo de asistencia estará disponible las 24hrs los 365 días del año para atender tus inquietudes.",
      icon: radar
    },
    {
      title: "NUEVA TECNOLOGÍA",
      description:
        "Nuestros equipos de última tecnología protegerán y controlarán tu casa todo el año las 24hrs.",
      icon: scan
    },
    {
      title: "SEGURIDAD",
      description:
        "Sentí la tranquilidad de saber que estaremos verificando tu sistema de forma constante. Si, 100% seguro.",
      icon: security
    }
  ];

  return (
    <div className="seguridadSection flex flexCol gap alignCenter">
      <div className="flex flexCol alignCenter textCenter">
        <h2>Sentí tranquilidad</h2>
        <p>Asesoramiento personalizado y gratuito en todo el país</p>
        <div className="seguridadDiv flex flexRow">
          {tranquilidadList.map((item) => (
            <div
              key={item.title}
              className="seguridadItem flex flexCol alignCenter"
            >
              <img src={item.icon} alt={item.title} />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Seguridad;
