import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import heroImage from "../assets/hero.jpeg";
import Marquee from "react-fast-marquee";
import Control from "./Control";
import SectionWithSwiper from "./SectionWithSwiper/SectionWithSwiper";
import Seguridad from "./Seguridad";

const Home = () => {
  const divStyle = {
    backgroundImage: `url(${heroImage})`,
    backgroundColor: "#f0f0f0"
  };
  return (
    <>
      <div className="home">
        <div className="container-lg">
          <div className="containerBox flexCol" style={divStyle}>
            <div className="landingContent flex flexCol alignCenter">
              <h1>Cotizá tu sistema de seguridad en muy pocos clicks</h1>
              <p>
                Obtené en pocos segundos las mejores opciones de seguridad para
                tu casa o negocio y contratá tu servicio con profesionales.
              </p>
              <h4>Con las mejores marcas</h4>
              <Marquee className="marquee" gradient={false} speed={50}>
                <img src="Logos/adt.png" alt="Logo ADT" />
                <img src="Logos/prosegur.png" alt="Logo ProSegur" />
                <img src="Logos/sps.jpg" alt="Logo SPS" />
                <img src="Logos/verisure.png" alt="Logo Verisure" />
                <img src="Logos/adt.png" alt="Logo ADT" />
                <img src="Logos/prosegur.png" alt="Logo ProSegur" />
                <img src="Logos/sps.jpg" alt="Logo SPS" />
                <img src="Logos/verisure.png" alt="Logo Verisure" />
              </Marquee>
              <Link to="/cotizador" className="buttonForm">
                Cotizar ahora
              </Link>
            </div>
          </div>
          <div className="flexCol infoBox">
            <div className="stepsHome flexRow">
              <h3>¿Cómo funciona?</h3>
              <div className="stepHomeBox flexRow">
                <div className="stepHome">
                  <span className="stepNumber">1</span>
                  <p>Ingresá lo que necesitás</p>
                </div>
                <div className="stepHome">
                  <span className="stepNumber">2</span>
                  <p>Elegís tu propuesta</p>
                </div>
                <div className="stepHome">
                  <span className="stepNumber">3</span>
                  <p>Protegés tu casa</p>
                </div>
              </div>
            </div>
            <Control />
            <SectionWithSwiper />
            <Seguridad />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
