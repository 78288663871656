import React, { useEffect } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";
import { Link } from "react-router-dom";

const SectionWithSwiper = () => {
  // Array de objetos con los datos de cada slide
  const slidesData = [
    {
      imageSrc: "src/prod-Panel.png",
      title: "Panel Inteligente",
      description:
        "Controla el sistema de seguridad desde un moderno panel touch, navega fácilmente por el menú y las configuraciones de tu alarma, ve los videos de las cámaras y úsalo como marco de fotos."
    },
    {
      imageSrc: "src/prod-Sirena-Ext.png",
      title: "Sirena exterior",
      description:
        "Emite un sonido y luz de alerta cuando se activa el sistema de seguridad."
    },
    {
      imageSrc: "src/prod-Sensor-mov-cam.png",
      title: "Sensor de movimiento con cámara",
      description:
        "Detecta movimientos en una área determinada y captura al instante imágenes de un evento de intrusión."
    },
    {
      imageSrc: "src/prod-Sensor-mov.png",
      title: "Sensor de movimiento",
      description:
        "Envía una señal cuando detecta movimiento en una zona determinada. Ángulo de visión 90° y alcance hasta 12 metros. Detecta hasta una mascota de 25 kg."
    },
    {
      imageSrc: "src/prod-contacto-magnético-interior.png",
      title: "Contacto magnético interior",
      description:
        "Envía una señal de alarma cuando detecta una apertura de puerta o ventana mientras el sistema se encuentre armado."
    },
    {
      imageSrc: "src/prod-app.png",
      title: "App ADT Smart Security",
      description:
        "Arma y desarma tu sistema de alarma, recibe notificaciones, alertas de eventos y mucho más desde tu smartphone."
    }
  ];

  return (
    <div className="flex flexCol swiperSec">
      <h2 className="textCenter blueColor">
        ¿Qué incluye tu <span>kit de alarma</span>?
      </h2>
      <Swiper
        modules={[Navigation]}
        className="mySwiper"
        spaceBetween={50}
        breakpoints={{
          320: {
            slidesPerView: 1
          },
          640: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          }
        }}
        navigation={true}
        loop={true}
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="imgBox">
              <img src={slide.imageSrc} alt="Bex" />
              <h4>{slide.title}</h4>
              <p>{slide.description}</p>
              <Link to="/cotizador" className="buttonForm">
                Cotizar ahora
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SectionWithSwiper;
