import "./styles.css";
import "./base.css";

/* PopUp import */
import Header from "./Header";
import Home from "./Home/Home";

/* Router */
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Send from "./Send";
import Cotizador from "./Cotizador/Cotizador";

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        {/* Routes */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/cotizador" element={<Cotizador />} />
          <Route path="/send" element={<Send />} />
          {/* Page Not Found */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/* End Routes */}
      </BrowserRouter>
    </div>
  );
}
