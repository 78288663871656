import React from "react";
import phone from "../../assets/phone.png";
import "./index.css";
import { Link } from "react-router-dom";

const Control = () => {
  return (
    <div className="controlSection flex flexRow gap alignCenter">
      <div className="flex flexCol alignLeft">
        <h2>Control total desde tu celular o tablet y desde donde estés</h2>
        <p>
          Cámara Interior IP, Micrófono digital y altavoz que te permitie
          monitorear desde tu celu todo lo que pasa en tu casa o negocio.
        </p>
        <Link to="/cotizador" className="buttonForm">
          Cotizar ahora
        </Link>
      </div>
      <img src={phone} alt="App" />
    </div>
  );
};

export default Control;
