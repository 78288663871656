import React from "react";
import "./index.css";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";

const Header = () => {
  const location = useLocation();

  return (
    <div className="header flex flexCenter flexRow">
      <div className="logo container-lg flex flexRow">
        <Link to={"/"}>
          <img src={logo} alt="Logo" />
        </Link>
        {location.pathname === "/" ? (
          <Link to="/cotizador" className="buttonForm">
            Cotizar alarma
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
